.footerLinks {
  max-width: 1200px;
}
.footerLinks li:nth-child(n + 7) {
  justify-self: end;
}
@media (min-width: 768px) {
  .footerLinks {
    max-width: 1200px;
  }
  .footerLinks li:nth-child(n + 7) {
    justify-self: start;
  }
}

.footerLogo {
  width: 200px;
  display: block;
}

.mobilePhoneBannerDivHeight {
  height: 84px;
}
