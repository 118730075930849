.navSection {
  height: 65px;
  z-index: 3;
}
.navSection-gridWrapper {
  grid-template-columns: 10rem auto;
}
.navDrawer *:first-child {
  box-shadow: inset 0 3px 3px -3px rgba(0, 0, 0, 0.25);
}

.navSection-spacer {
  height: 65px;
}
