/* ripped from codepen: https://codepen.io/TheeBryanWhite/pen/KwdWQP */
.hamburger {
  display: inline-block;
  height: 26px;
  margin-right: 27px;
}
.hamburger span.main-nav-toggle {
  display: block;
  width: 28px;
  height: 16px;
  right: 20px;
  position: absolute;
  top: 23px;
}
.hamburger span.main-nav-toggle:after,
.hamburger span.main-nav-toggle:before {
  content: '';
  position: absolute;
  top: 0;
  height: 0;
  border-bottom: 2px solid #717171;
  width: 100%;
  left: 0;
  right: 0;
  transition: all ease-out 0.3s;
}
.hamburger span.main-nav-toggle:after {
  top: 100%;
}
.hamburger span.main-nav-toggle i {
  display: block;
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  height: 2px;
  background-color: #717171;
  width: 100%;
  position: absolute;
  top: 50%;
  transition: all ease-out 0.1s;
}
.hamburger span.main-nav-toggle.active-menu:after {
  transform: rotate(-45deg);
  transform-origin: center;
  top: 50%;
}
.hamburger span.main-nav-toggle.active-menu:before {
  transform: rotate(45deg);
  transform-origin: center;
  top: 50%;
}
.hamburger span.main-nav-toggle.active-menu i {
  opacity: 0;
}
