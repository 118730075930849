@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lora';
  src: url('/src/fonts/Lora-Medium.woff2') format('woff2'),
    url('/src/fonts/Lora-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/fonts/Montserrat-Regular.woff2') format('woff2'),
    url('/src/fonts/Montserrat-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/fonts/Montserrat-Medium.woff2') format('woff2'),
    url('/src/fonts/Montserrat-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/fonts/Montserrat-SemiBold.woff2') format('woff2'),
    url('/src/fonts/Montserrat-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/src/fonts/Montserrat-Bold.woff2') format('woff2'),
    url('/src/fonts/Montserrat-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
