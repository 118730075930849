.heroSpacer-wrapper {
  height: 100px;
  width: 100%;
  margin: 0;
  position: relative;
}
.heroSpacer-innerWrapper {
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.heroSpacer-svg {
  width: 100%;
  height: 100px;
}

.heroSpacer-svg polygon {
  fill: white;
}
